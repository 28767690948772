<template>
    <div class="aboutContainer">
        <div class="container">
            <h1>
                使命與願景
            </h1>
            <h3>
                <strong>使命 </strong>
            </h3>
            <p>
                (一)「願景」：以共享、共好的全新生活為中心思想，給予消費者最高的性價比、便利、高效的物流生活，打造物流業的首選品牌。
            </p>
            <p>
                (二)「使命」：引領物流市場，整合搬家、托運、配送三大領域，建立「共享物流網」。為消費者帶來「親民、利民、便民」的超值服務，成為獨步全球，台灣第一的共享物流平台。
            </p>
            <p>
                (三)「定位」：共享物流、共好生活的傳遞者。
            </p>
            <p>
                (四)「目標市場」：主力客群:  學生(大四、碩博士生)、小資上班族，次要客群：自營商(大型物件傢俱運送)。
            </p>
            <p>
                (五)「核心價值」：整合搬家、托運、配送，提供高性價比的「共享物流網」，並透過科技改變消費者傳統物流習慣。
            </p>
            <p>
                (六)「品牌屬性」：安全、利民、效率、共享。
            </p>
            <p>
                (七)「品牌承諾」：安心速達、精心呵護。
            </p>
            <p>
                (八)「品牌標語」：Share Your Joy.，盒你共享這份喜悅。
            </p>

            <h3>
                <strong>BIGBOX的「差 異 化」 </strong>
            </h3>
            <p>
                (1)採「最大材積」的搬家紙箱，並不限配送距離，全省均一價。
            </p>
            <p>
                (2)大型物件運送， 1件也能送。
            </p>
            <p>
                (3)解決學生、小資上班族搬家需求。
            </p>
            <p>
                (4)高性價比的「共享物流」。
            </p>

            <h3>
                <strong>BIGBOX的「核心構想」 </strong>
            </h3>
            <p>
                瞄準共享經濟商業脈絡，整合搬家、托運、配送三大領域，提供高性價比的「共享物流網」，創造市場區隔，透過科技打造「高效、利民、安心、親切」的共享物流平台，將物流生活化。
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Vision"
    }
</script>

<style scoped>

</style>